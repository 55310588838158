@import "../../../../core/styles/refrences/index.scss";

.pmx-user-by-phone-number {
  .dropdown-selector {
    a {
      span {
        font-size: 14px !important;
      }
    }
  }

  .content {
    flex-direction: column;
  }

  .users-table {
    width: 100%;
    margin: 24px 0;

    [data-column-id="roles"] {
      .pmx-badge:nth-child(2),
      .pmx-badge:nth-child(3) {
        margin-right: 2px;
      }
    }
  }

  .filter-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;

    @include pmx-pre-lg {
      flex-direction: column;
      background: $white;
      border-top: 1px solid $table-border;
    }

    > .ant-divider {
      display: none;
    }

    .buttons,
    .fields {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    .buttons {
      width: 100%;
      justify-content: flex-end;

      @include pmx-pre-lg {
        flex-direction: column;
        justify-content: space-between;
        margin: 16px auto;
        gap: 16px;
      }

      > span {
        cursor: pointer;
        width: max-content;
        color: $danger;
        min-width: 60px;
      }

      > .pmx-button {
        min-width: unset;
        width: 100%;
        margin: 0;

        @include pmx-pre-lg {
          margin: auto 0;
        }

        &:first-child {
          max-width: 150px;
          max-width: 150px;
        }

        &:last-child {
          margin-right: 16px;
          max-width: 150px;
        }

        button {
          font-weight: 500;
        }
      }
    }

    .fields {
      width: 100%;
      @include pmx-pre-lg {
        flex-direction: column;
      }

      > div {
        min-width: 130px;
        width: 100%;
        color: $blue;
        border-radius: 10px;
        margin-left: 16px;
        margin-right: 0;

        @include pmx-pre-lg {
          margin-left: 0;
        }
      }

      &:first-child {
        @include pmx-pre-lg {
          margin-top: 16px;
        }
      }
    }
  }
}

.delete-modal {
  .modal-card {
    p {
      font-weight: 700;
      font-size: 20px;
      text-align: center;
    }

    height: max-content;

    .buttons {
      margin-top: 32px;

      .pmx-button {
        button {
          width: 163px;
          height: 56px;
        }

        &:last-child {
          margin-right: 16px;
        }
      }
    }
  }
}

@include pmx-pre-lg {
  .pmx-user-by-phone-number {
    .filter-row {
      .fields .pmx-dropdown {
        margin-top: 16px;
      }
      .buttons {
        flex-direction: column;
        width: 100%;

        > .pmx-button {
          width: 100%;
          max-width: unset;

          &:last-child {
            max-width: unset;
            margin-right: 0;
          }

          button {
            max-width: unset;
            width: 100%;
            margin: 0;
            height: 48px;
          }
        }
      }
    }
  }
}
