@import "../../../../core/styles/refrences/index.scss";

.pmx-signin .content .code {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: auto 0;

  .signin-code-main {
    margin-top: 40px;
    display: flex;
    flex-direction: column;

    @include pmx-pre-lg {
      margin-top: 24px;
    }

    .signin-code-input {
      direction: ltr;
      width: 100% !important;

      > div {
        display: flex;
        justify-content: space-between;
        align-items: center;

        > input {
          background: $code-bg;
          border-radius: 8px;
          border: none;
          font-size: 48px;
          color: $black;
          padding: 8px;

          @include pmx-pre-md {
            margin: 0 5px;
          }
        }
      }
    }

    .signin-code-input.has-error {
      input {
        border: 1px solid $danger-border;
        background: $danger-light;
      }
    }

    .signin-code-stats {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin: 32px 0 0 0;

      @include pmx-pre-lg {
        margin-top: 24px;
      }

      span.resend {
        cursor: pointer;
        color: $blue;
        font-size: 16px;
      }

      span:last-child {
        min-width: 50px;
        font-size: 16px;
      }

      .signin-code-timer {
        align-self: flex-end;
        display: flex;
        justify-content: center;
        align-items: center;

        > div {
          left: auto !important;
          top: auto !important;

          > svg {
            display: none;
          }

          > div {
            position: relative !important;
            height: 28px !important;
            width: 65px !important;
          }
        }
      }

      .signin-code-errors {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        column-gap: 4px;
        color: $danger;
        min-width: 50px;
        font-size: 12px;
      }
    }

    .signin-code-change-number {
      margin-top: 10px;
      color: $blue;
      cursor: pointer;
      font-size: 16px;
    }
  }

  > .pmx-button {
    margin-top: 32px;
    margin-right: auto;
    margin-left: auto;

    @include pmx-pre-lg {
      margin-top: 24px;
    }
  }

  .footer-text {
    margin: 24px auto 0 auto;
    @include pmx-pre-lg {
      margin-top: 16px;
    }
    p {
      font-size: 16px;

      span {
        color: $blue;
        cursor: pointer;
      }
    }
  }
}
