@use "sass:selector";

$primary: linear-gradient(90deg, #3364e4 10.42%, #333dc2 100%);
$black: #121212;
$gray: #e5e5e5;
$whiteGray: #f9fcff;

$lightPurple: #7093ec;

$white: #ffffff;
$border: #c2d1f7;

$success: #44c58f;
$danger: linear-gradient(91.95deg, #fc2a2a -14.4%, #ff4646 91.65%);
$danger-light: #fff1f1;
$danger-border: #ff4a4a;
$blue: #3364e4;
$red: #ff3d00;
$green: #1fcb45;
$orange: #ff8a00;

$sell: linear-gradient(91.95deg, #fc2a2a -14.4%, #ff4646 91.65%);

$blueGradient: linear-gradient(
                92.36deg,
                rgba(0, 114, 255, 0.7) -46.11%,
                rgba(0, 114, 254, 0.7) 92.47%
);

$secondary: #f9fafe;
$skyBlue: #dfeeff;
$lightBlue: #4598fe;
$lightGreen: #ebffeb;
$cancel: #ffeceb;
$lightOrange: #fff7eb;
$lightGray: #eeeeee;

$code-bg: #f5f5f5;
$background: #3364e40d;

$disable-primary: linear-gradient(
                92.36deg,
                rgba(0, 81, 182, 0.7) -46.11%,
                rgba(0, 114, 254, 0.7) 92.47%
);
$dark-blue: #0051b6;

$menuBorder: #80b8ff50;
$table-border: #d9dfff;
$pasteal-blue: #eaeaff;
