@import "../../core/styles/refrences/index.scss";

.pmx-modal {
  .modal-card {
    position: fixed;
    filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.08));
    left: calc(50% - 203.5px);
    top: calc(30% - 89.5px);
    width: 407px;
    min-height: 179px;
    background: #ffffff;
    border-radius: 20px;
    padding: 32px;
    display: flex;
    flex-direction: column;

    .buttons {
      display: flex;
    }
  }
}
