@import "core/styles/refrences/index.scss";

.pmx-dashboard-bank-accounts-create {
  padding: 24px;
  height: auto;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  column-gap: 50px;
  background: $white;

  border-radius: 20px;
  h3 {
    margin-bottom: 24px;
  }

  form {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    padding: 8px;

    .pmx-input {
      width: 100%;
    }

    textarea.ant-input {
      margin-top: 24px;
      width: 100%;
      min-height: 250px;
      max-height: 250px;
    }

    .ant-spin-nested-loading {
      min-width: 100%;
      justify-content: center;
      display: flex;
      justify-content: center;

      .ant-spin-container {
        width: max-content;
      }
      label {
        top: 0px;
        right: 0;
        position: absolute;
        font-size: 14px;
      }
    }

    .select {
      position: relative;
      width: 525px;

      label {
        font-size: 14px;
        position: absolute;
        top: -24px;
      }

      .ant-select {
        width: 100%;

        .ant-select-selector {
          height: 60px;
          padding: 13px 12px 13px;
          border: 1px solid $border;
          background: $secondary;
          border-radius: 20px;
          @include pmx-pre-lg {
            margin: auto;
            max-width: 370px;
          }
        }
      }
    }

    > .row,
    .ant-row {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-top: 24px;

      &.center {
        justify-content: center;
      }

      .select {
        position: relative;
        label {
          top: -20px;
          right: 0;
          position: absolute;
          font-size: 14px;
          @include pmx-pre-lg {
            right: 17%;
          }
        }
      }

      .pmx-input {
        width: 50%;
      }

      .pmx-input:last-child {
        margin-right: 24px;
      }

      .pmx-button {
        margin-top: 24px;
        width: 50%;
      }
    }

    .buttons {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      @include pmx-pre-lg {
        justify-content: space-between;
      }

      .pmx-button {
        margin: 0;
        margin-top: 24px;

        &:last-child {
          margin-right: 24px;
        }

        button {
          height: 48px !important;
        }
      }
    }

    .bank-accounts-transfer {
      direction: ltr;
      margin-top: 24px;
      width: 100%;

      @include pmx-pre-lg {
        flex-direction: column;
      }

      .ant-transfer-list-header-selected {
        direction: rtl;
      }

      .ant-transfer-list-content {
        direction: rtl;

        .ant-transfer-list-content-item-text {
          margin-right: 4px;
        }
      }

      .ant-transfer-operation {
        @include pmx-pre-lg {
          margin: 16px 0;
        }
      }

      .ant-transfer-list {
        direction: ltr;
        width: 50%;
        border-radius: 10px;

        @include pmx-pre-lg {
          width: 100%;
        }
      }
    }

    > .pmx-input {
      width: 100%;
      margin-top: 24px;
    }
  }
}
