@import "../../../core/styles/refrences/index.scss";

.pmx-dashboard {
  display: flex;
  max-width: 1920px;
  margin: auto;
  background: $background;
  height: 100vh;
  overflow-y: hidden;

  main {
    overflow-y: scroll;
    width: calc(100% - 13%);
    background: $background;
    padding: 48px 16px 0 0;

    @include pmx-pre-lg {
      width: 100%;
      padding: 20px 0 150px;
    }
  }
}
