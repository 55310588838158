@import "../../../../../core/styles/refrences/index.scss";

.pmx-dashboard-surveys-edit {
  .pmx-card {
    padding: 40px 30px;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    column-gap: 50px;
    background: $white;
    border-radius: 20px;

    h4 {
      display: none;
    }

    @include pmx-pre-lg {
      margin: auto;
    }

    > .content {
      width: 100%;
      flex-direction: column;

      > div {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .ant-row {
          width: 100%;
          display: flex;
          justify-content: start;
          align-items: center;
          margin-top: 32px;
          column-gap: 32px;
          flex-wrap: nowrap;

          .pmx-dropdown {
            @include pmx-pre-lg {
              min-width: unset;
              max-width: unset;
            }
          }

          &:first-child {
            margin-top: 0;
          }

          .textarea {
            position: relative;
            width: 100%;

            textarea.ant-input {
              width: 100%;
              height: 150px;
              resize: none;
              font-family: YekanBakh;
            }

            .texts {
              display: flex;
            }

            label {
              font-size: 16px;
              color: #7093ec;
              position: absolute;
              top: -24px;
            }
          }

          .ant-col {
            margin-right: 32px;
            min-width: 70px;
            width: calc(50% - 64px);

            &:first-child {
              margin-right: 0;
            }

            &:last-child {
              margin-left: 16px;
            }
          }

          > span,
          .ant-col > span {
            font-size: 16px;
            color: $lightPurple;
          }

          > p,
          .ant-col > p {
            font-size: 16px;

            &.warning {
              color: $orange;
            }

            &.success {
              color: $green;
            }

            &.error {
              color: $red;
            }
          }
        }

        > .ant-row {
          width: auto;
        }
      }

      .buttons {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-top: 24px;

        @include pmx-pre-lg {
          justify-content: space-between;
        }

        .pmx-button {
          margin-right: 16px;

          &:first-child {
            margin-right: 0;
          }

          button {
            height: 48px !important;
          }
        }
      }

      > .pmx-input {
        width: 100%;
        margin-top: 24px;
      }
    }
  }

  .reject-survey-modal {
    .modal-card {
      height: max-content;
      width: max-content;

      p {
        font-weight: 700;
        font-size: 20px;
        text-align: center;
      }

      > .pmx-input {
        margin-top: 32px;
        max-width: calc(100% - 32px);
        margin-right: 0;
      }

      .buttons {
        margin-top: 24px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .pmx-button {
          button {
            width: 163px;
            height: 56px;
            margin: 0;
            @include pmx-pre-lg {
              height: 50px;
            }
          }

          &:last-child {
            margin-right: 16px;
          }
        }
      }
    }
  }
}

@include pmx-pre-lg {
  .pmx-dashboard-surveys-edit {
    padding: 0 16px;

    .pmx-card {
      padding: 16px;

      h4 {
        display: block;
      }

      .content {
        .buttons {
          flex-direction: column;
          row-gap: 16px;

          .pmx-button {
            margin: 0;
          }
        }

        > div:first-child {
          flex-direction: column;

          .right {
            width: 100%;

            .ant-row {
              justify-content: space-between;
              width: 100%;

              .pmx-dropdown {
                min-width: unset;
                width: 100%;
              }
            }
          }

          .ant-row {
            width: 100%;

            .ant-col {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              width: 100%;

              .ant-image {
                margin-top: 32px;
              }
            }
          }
        }
      }
    }
  }
}
