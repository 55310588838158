@import "../../../../../../core/styles/refrences/index.scss";

.pmx-pm-sum-buy-and-sell {
  .content {
    display: flex;
    flex-direction: column;

    .filter-row {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0;

      @include pmx-pre-lg {
        flex-direction: column;
        background: $white;
        border-top: 1px solid $table-border;
      }

      > .ant-divider {
        display: none;
      }

      .buttons,
      .fields {
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }

      .buttons {
        width: 100%;
        justify-content: space-around;

        @include pmx-pre-lg {
          margin-top: 16px;
          flex-direction: column;
          justify-content: space-between;
          margin-bottom: 16px;
        }

        > span {
          cursor: pointer;
          width: max-content;
          color: $danger;
          min-width: 60px;
        }

        > .pmx-button {
          min-width: unset;
          width: 100%;
          margin: 0;
          margin-right: 16px;

          &:first-child {
            margin-right: 0;
          }

          @include pmx-pre-lg {
            margin: 0 auto;
            font-size: 12px;
            width: 100%;
            max-width: unset;
          }
          max-width: 150px;

          button {
            font-weight: 500;
            font-size: 12px;
          }
        }
      }

      .fields {
        width: 100%;
        @include pmx-pre-lg {
          flex-direction: column;
        }

        .rmdp-container {
          .pmx-input {
            width: unset;
          }
        }

        > div {
          min-width: 135px;
          width: 100%;
          color: $blue;
          border-radius: 10px;
          margin-left: 16px;
          margin-right: 0;

          @include pmx-pre-lg {
            margin-left: 0;
          }
        }

        &:first-child {
          @include pmx-pre-lg {
            margin-top: 16px;
          }
        }
      }
    }

    .pmx-chart {
      margin-inline: auto;

      @include pmx-pre-lg {
        width: 320px !important;
        height: 240px !important;
      }

      .recharts-surface {
        width: 100%;
      }
    }
  }
}