@import "../../../../../core/styles/refrences/index.scss";

.order-edit-modal {
  width: 100%;
  height: 100%;
  position: relative;
  .modal-card {
    width: 600px;
    height: max-content;
    top: calc(50% - 200px);
    left: calc(50% - 250px);
    padding: 24px;
    display: flex;
    flex-direction: column;
    @include pmx-pre-lg {
      width: 80%;
      padding: 16px;
      left: 6%;
    }

    .buttons {
      margin-inline: auto;
      display: flex;
      align-items: center;
      width: 85%;
      margin-top: 24px;

      .pmx-button {
        button {
          height: 60px;
          @include pmx-pre-lg {
            height: 50px;
          }
        }
        &:last-child {
          margin-right: 16px;
        }
      }
    }

    h3 {
      font-size: 16px;
      font-weight: 700;
      text-align: center;
    }

    .row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-top: 24px;

      label {
        font-size: 16px;
      }

      .pmx-dropdown {
        margin-right: 32px;
      }

      span {
        font-size: 14px;
        color: $lightPurple;
      }

      p {
        font-size: 14px;
      }

      &.text-overflow {
        p {
          width: 100px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      &.copy {
        p {
          margin-right: auto;
        }
        svg {
          margin-right: 8px;
          cursor: pointer;
          width: 16px;
        }
      }
    }
  }
}
