@import "../../../../../../core/styles/refrences/index.scss";

.pmx-buys-waiting-for-accept-receipt {
  .content {
    flex-direction: column;
  }

  .orders-table {
    width: 100%;
    margin: 24px 0;

    .cell.pair {
      direction: ltr;
      display: flex;
      white-space: nowrap;

      img {
        width: 20px;
        height: 20px;
        margin-left: 4px;
        margin-right: 4px;
        @include pmx-pre-lg {
          width: 16px;
          height: 16px;
        }
      }
    }

    .copiable {
      .anticon {
        margin-right: 8px;

        svg {
          path {
            fill: $blue;
          }
        }
      }
    }
  }



  .filter-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;

    @include pmx-pre-lg {
      flex-direction: column;
      background: $white;
      border-top: 1px solid $table-border;
    }

    > .ant-divider {
      display: none;
    }

    .buttons,
    .fields {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    .buttons {
      width: 100%;
      justify-content: space-around;

      @include pmx-pre-lg {
        margin-top: 16px;
        flex-direction: column;
        justify-content: space-between;
        margin-bottom: 16px;
      }

      > span {
        cursor: pointer;
        width: max-content;
        color: $danger;
        min-width: 60px;
      }

      > .pmx-button {
        min-width: unset;
        width: 100%;
        margin: 0;
        margin-right: 16px;

        &:first-child {
          margin-right: 0;
        }

        @include pmx-pre-lg {
          margin: 0 auto;
          font-size: 12px;
          width: 100%;
          max-width: unset;
        }
        max-width: 150px;

        button {
          font-weight: 500;
          font-size: 12px;
        }
      }
    }

    .fields {
      width: 100%;
      @include pmx-pre-lg {
        flex-direction: column;
      }

      .rmdp-container {
        .pmx-input {
          width: unset;
        }
      }

      > div {
        min-width: 135px;
        width: 100%;
        color: $blue;
        border-radius: 10px;
        margin-left: 16px;
        margin-right: 0;

        @include pmx-pre-lg {
          margin-left: 0;
        }
      }

      &:first-child {
        @include pmx-pre-lg {
          margin-top: 16px;
        }
      }
    }
  }
}

.confirm-payout-modal {
  .modal-card {
    width: 500px;
    justify-content: space-between;

    @include pmx-pre-lg {
      width: 70%;
      left: 11%;
      padding: 16px;
    }

    p {
      font-weight: 500;
      font-size: 18px;
      text-align: center;
      @include pmx-pre-lg {
        font-size: 14px;
      }
    }

    .buttons {
      margin-top: 24px;

      .pmx-button {
        display: flex;
        align-items: center;
        justify-content: center;

        button {
          width: 180px;
          height: 60px;
        }
      }
    }
  }
}
@include pmx-pre-lg {
  .pmx-buys-waiting-for-accept-receipt {
    .filter-row {
      .fields {
        div {
          margin-top: 16px;

          &:first-child {
            margin-top: 0;
          }
        }

        .pmx-input {
          width: 100%;
          max-width: unset;
        }
      }

      .buttons {
        .pmx-button {
          margin-top: 16px;

          &:first-child {
            margin-top: 0;
          }

          button {
            height: 50px;
          }
        }
      }
    }
  }
}
