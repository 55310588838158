@import "../../../../../core/styles/refrences/index.scss";

  .pmx-balances {
    justify-content: space-between;
    display: flex;
    width: 100%;

    .balance {
      border-radius: 16px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 27%;
      min-height: 120px;
      max-height: 120px;
      padding: 16px;
      background-color: white;

      span {
        &:first-child {
          font-size: 16px;
          font-weight: 600;
          color: #3364e4;
          display: flex;
          align-items: center;
          gap: 8px;
        }

        &:last-child {
          font-size: 18px;
          font-weight: 600;
          direction: ltr;
        }
      }
    }
  }

