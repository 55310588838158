@import "../../../../core/styles/refrences/index.scss";

.pmx-users {
  .dropdown-selector {
    a {
      span {
        font-size: 14px !important;
      }
    }
  }

  .content {
    flex-direction: column;

    .pmx-card {
      .content {
        .filter-row {
          .buttons {
            .rmdp-container {
              width: 25%;
            }
          }
        }
      }
    }
  }

  .users-table {
    width: 100%;
    margin: 24px 0;

    [data-column-id="roles"] {
      .pmx-badge:nth-child(2),
      .pmx-badge:nth-child(3) {
        margin-right: 2px;
      }
    }
  }

  .filter-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    padding: 0;

    @include pmx-pre-lg {
      flex-direction: column;
      background: $white;
      border-top: 1px solid $table-border;
    }

    > .ant-divider {
      display: none;
    }

    .buttons,
    .fields {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      box-sizing: border-box;
    }

    .buttons {
      width: 100%;
      justify-content: flex-end;
      margin-top: 16px;

      @include pmx-pre-lg {
        justify-content: space-between;
        margin: 16px auto;
        flex-direction: column;
        gap: 16px;
      }

      > span {
        cursor: pointer;
        width: max-content;
        color: $danger;
        min-width: 60px;
      }

      > .pmx-button {
        min-width: unset;
        max-width: 150px;
        width: 100%;
        margin: 0;
        margin-right: 16px;

        @include pmx-pre-lg {
          margin: 0 auto;
          width: 100%;
          max-width: unset;
        }

        &:first-child {
          max-width: 150px;
        }

        &:first-child {
          margin-right: 0;
        }

        button {
          font-weight: 500;
        }
      }
    }

    .fields {
      width: 100%;
      @include pmx-pre-lg {
        flex-direction: column;
      }

      > div {
        min-width: 130px;
        width: 100%;
        color: $blue;
        border-radius: 10px;
        margin-left: 16px;
        margin-right: 0;

        @include pmx-pre-lg {
          margin-left: 0;
        }
      }

      &:first-child {
        @include pmx-pre-lg {
          margin-top: 16px;
        }
      }
    }
  }
}

.delete-modal {
  .modal-card {
    p {
      font-weight: 700;
      font-size: 20px;
      text-align: center;
    }

    height: max-content;

    .buttons {
      margin-top: 32px;

      .pmx-button {
        button {
          width: 163px;
          height: 56px;

          @include pmx-pre-lg {
            height: 50px;
          }
        }

        &:last-child {
          margin-right: 16px;
        }
      }
    }
  }
}

.financial-settings-modal {
  .modal-card {
    top: calc(50% - 271px);
    left: calc(50% - 235px);
    @include pmx-pre-lg {
      left: 6%;
      width: 80%;
      padding: 16px;
    }
  }
  .pmx-input,
  .ant-switch {
    margin-top: 16px;
    @include pmx-pre-lg {
      width: 100%;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
  }

  p {
    margin-top: 32px;
  }

  .pmx-input:nth-child(2) {
    margin-top: 32px;
  }

  .buttons {
    margin-top: 24px;
    display: flex;

    .pmx-button button {
      @include pmx-pre-lg {
        height: 50px;
      }
    }
  }

  .content > .pmx-button {
    margin-top: 16px;
  }

  .pmx-input {
    margin-top: 24px;
  }

  .pmx-button {
    button {
      height: 56px;
    }
  }

  .buttons {
    box-sizing: border-box;
    .pmx-button:last-child {
      margin-right: 16px;
    }
  }
}

@include pmx-pre-lg {
  .pmx-users {
    .filter-row {
      .fields .pmx-dropdown {
        margin-top: 16px;
      }
      .buttons {
        flex-direction: column;
        width: 100%;
        gap: 0;

        .rmdp-container {
          width: 100%;
          margin-top: 16px;

          &:first-child {
            margin-top: 0;
          }

          .pmx-input {
            max-width: unset;
            margin: 0;
            width: 100%;
            border-radius: 10px;
          }
        }

        > .pmx-button {
          width: 100%;
          max-width: unset;
          margin: 16px 0 0;

          &:first-child {
            margin-top: 0;
          }

          button {
            max-width: unset;
            width: 100%;
            margin: 0;
            height: 50px;
          }
        }
      }
    }
  }
}
