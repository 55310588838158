@import "../../../../../core/styles/refrences/index.scss";

.pmx-dashboard-users-edit {
  .pmx-card {
    padding: 40px 30px;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    column-gap: 50px;
    background: $white;
    border-radius: 20px;
    @include pmx-pre-lg {
      margin: auto;
    }

    h4 {
      display: none;
    }
  }
  form {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    padding: 8px;

    .switch-col {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
    }

    .ant-row {
      width: 100%;
      display: flex;
      justify-content: start;
      align-items: center;
      margin-top: 32px;
      column-gap: 32px;
      flex-wrap: nowrap;

      &:nth-child(7) {
        @include pmx-pre-lg {
          column-gap: 16px;
        }
      }

      &:first-child {
        margin-top: 0;
      }

      .ant-col {
        margin-right: 32px;
        min-width: 70px;
        width: calc(50% - 64px);

        &:first-child {
          margin-right: 0;
        }

        &:last-child {
          margin-left: 16px;
        }

        .pmx-input {
          margin: 0;
          @include pmx-pre-lg {
            width: 120%;
          }

          label {
            white-space: nowrap;
          }

          input {
            @include pmx-pre-lg {
              font-size: 12px;
            }
          }
        }

        .rmdp-container {
          width: calc(100%);
        }
      }
    }

    .file-uploader {
      display: flex;
      flex-direction: column;
      width: 30%;
      background: $skyBlue;
      border-radius: 10px;
      padding: 17px;
      cursor: pointer;
      margin: 0 auto;

      @include pmx-pre-lg {
        width: 150px;
        height: 64px;
        border-radius: 8px;
        padding: 10px;
        border-radius: 8px;
      }

      .ant-upload {
        background: none;
        border: none;
        padding: 0;
      }

      button.upload-button {
        background: transparent;
        border: none;
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0;
        cursor: pointer;

        > p {
          margin-top: 8px;
        }

        svg {
          width: 32px;
          height: 32px;

          @include pmx-pre-lg {
            width: 20.36px;
            height: 20.36px;
          }

          path {
            fill: $lightBlue;
          }
        }
      }
    }

    .has-label {
      position: relative;
      width: calc(100% - 32px);

      label {
        font-size: 14px;
        position: absolute;
        top: -24px;
        white-space: nowrap;
      }
    }

    .select {
      position: relative;
      @include pmx-pre-lg {
        width: 150%;
      }

      label {
        font-size: 14px;
        position: absolute;
        top: -24px;
        white-space: nowrap;
      }

      .ant-select {
        width: 100%;

        .ant-select-selector {
          height: 60px;
          padding: 13px 12px 13px;
          border: 1px solid $border;
          background: $secondary;
          border-radius: 20px;
        }
      }
    }

    textarea.ant-input {
      margin-top: 24px;
      width: 100%;
      min-height: 250px;
      max-height: 250px;
    }

    > .row {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .pmx-input {
        width: 50%;
      }

      .pmx-input:last-child {
        margin-right: 24px;
      }

      .pmx-button {
        margin-top: 24px;
        width: 50%;
      }
    }

    .buttons {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      @include pmx-pre-lg {
        justify-content: space-between;
      }

      .pmx-button {
        margin: 0;
        margin-top: 24px;

        &:last-child {
          margin-right: 24px;
        }

        button {
          height: 48px !important;
        }
      }
    }

    .users-transfer {
      direction: ltr;
      margin-top: 24px;
      width: 100%;

      @include pmx-pre-lg {
        flex-direction: column;
      }

      .ant-transfer-list-header-selected {
        direction: rtl;
      }

      .ant-transfer-list-content {
        direction: rtl;

        .ant-transfer-list-content-item-text {
          margin-right: 4px;
        }
      }

      .ant-transfer-operation {
        @include pmx-pre-lg {
          margin: 16px 0;
        }
      }

      .ant-transfer-list {
        direction: ltr;
        width: 50%;
        border-radius: 10px;

        @include pmx-pre-lg {
          width: 100%;
        }
      }
    }

    > .pmx-input {
      width: 100%;
      margin-top: 24px;
    }
  }
}
@include pmx-pre-lg {
  .pmx-dashboard-users-edit {
    padding: 0 16px;

    .pmx-card {
      padding: 16px 16px 24px;
      width: 100%;

      h4 {
        display: block;
        margin-bottom: 24px;
      }
    }

    form {
      padding: 0;

      .ant-row {
        width: 100%;
        row-gap: 32px;
        flex-direction: column;

        .file-uploader {
          width: 100%;
        }

        .switch-col {
          justify-content: space-between;
        }

        .ant-col {
          width: 100%;
          margin: 0;

          &:last-child {
            margin: 0;
          }

          .pmx-input {
            width: 100%;
          }

          .select {
            width: 100%;
            .ant-select {
              .ant-select-selector {
                height: 50px;
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}
