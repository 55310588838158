@font-face {
    font-family: Roboto;
    font-style: normal;
    font-weight: 700;
    /*src: url("../fonts/eot/Yekan\ Bakh\ Fa\ 04\ Regular.eot");*/
    /*src: url("../fonts/woff/Yekan\ Bakh\ Fa\ 04\ Regular.woff") format("woff"),*/
    /* IE6-8 */
    src: url("../En/Roboto-Bold.ttf") format("truetype");
}

@font-face {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    /*src: url("../fonts/eot/Yekan\ Bakh\ Fa\ 04\ Regular.eot");*/
    /*src: url("../fonts/woff/Yekan\ Bakh\ Fa\ 04\ Regular.woff") format("woff"),*/
    /* IE6-8 */
    src: url("../En/Roboto-Medium.ttf") format("truetype");
}

@font-face {
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    /*src: url("../fonts/eot/Yekan\ Bakh\ Fa\ 03\ Light.eot");*/
    /*src: url("../fonts/woff/Yekan\ Bakh\ Fa\ 03\ Light.woff") format("woff"),*/
    /* IE6-8 */
    src: url("../En/Roboto-Regular.ttf") format("truetype");
}

@font-face {
    font-family: Roboto;
    font-style: normal;
    font-weight: 200;
    /*src: url("../fonts/eot/Yekan\ Bakh\ Fa\ 03\ Light.eot");*/
    /*src: url("../fonts/woff/Yekan\ Bakh\ Fa\ 03\ Light.woff") format("woff"),*/
    /* IE6-8 */
    src: url("../En/Roboto-Thin.ttf") format("truetype");
}
