@import "../../core/styles/refrences/index.scss";

.pmx-badge {
  max-width: 180px;
  width: max-content;
  margin-top: 5px;

  div {
    border: 0;
    outline: 0;
    width: max-content;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 5px;
    height: max-content;
    padding: 3px 8px;
    direction: ltr;
    min-height: 28px;

    &:hover {
      cursor: pointer;
    }

    &.success {
      background: $lightGreen;

      span {
        color: $green !important;
      }
    }

    &.primary {
      background: $primary;
      color: $white;
    }

    &.secondary {
      background: $secondary;
      color: $white;
    }

    &.error {
      background: $cancel;

      span {
        color: $red !important;
      }
    }

    &.waiting {
      background: $lightOrange;

      span {
        color: $orange !important;
      }
    }

    &.info {
      background: $pasteal-blue;

      span {
        color: $blue !important;
      }
    }

    img {
      margin-left: 5px;
    }

    span {
      font-weight: 500;
      font-size: 12px;
    }
  }
}
