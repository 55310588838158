@import "core/styles/refrences/index";

.pmx-edit-market-content {
  .content {
    .ant-segmented {
      width: 100%;
      height: 48px;
      display: flex;
      align-items: center;
      border-radius: 8px;

      .ant-segmented-group {
        display: flex;
        justify-content: space-between;

        .ant-segmented-item {
          min-width: calc(100% / 3);
          padding: 8px;
          border-radius: 8px;
        }
      }
    }

    .pmx-card {
      .content {
        display: flex;
        flex-direction: column;

        label {
          &:last-child {
            margin-top: 32px;
          }
        }

        .pmx-dropdown {
          border-radius: 20px;

          .dropdown-items {
            width: 100%;
          }
        }


        .pmx-input {
          margin-top: 32px;
        }

        .pmx-button {
          margin-top: 32px;
          max-width: 500px;

          &:last-child {
            margin-top: 16px;
          }
        }

        .tox {
          width: 100%;
          margin-top: 16px;
        }
      }
    }
  }
}
