@import "../../../../core/styles/refrences/index.scss";

.pmx-signin .content .login-with-password {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: auto 0;

  .pmx-input {
    margin-top: 40px;
    margin-right: 0;
    max-width: unset;
    width: 100%;

    @include pmx-pre-lg {
      margin-top: 24px;
      width: 90%;
    }
  }

  .pmx-button {
    margin-top: 40px;
    margin-right: 0;
    width: 100%;

    @include pmx-pre-lg {
      margin-top: 24px;
      width: 90%;
    }
  }

  .forget {
    margin: 22px 0 0 0;
    color: $blue;
    font-size: 16px;
    cursor: pointer;

    @include pmx-pre-lg {
      margin-top: 24px;
    }
  }
}
