.pmx-remove-wallet-modal {
  .modal-card {
    min-height: unset;

    div {
      display: flex;
      margin-top: 32px;
      height: 48px;
      gap: 16px;

      .pmx-button {
        margin: unset;
        height: 100%;
        button {
          height: 100%;
        }
      }
    }
  }
}