@import "../../core/styles/refrences/index.scss";

.pmx-card {
  width: 100%;
  padding: 24px;
  background: $white;
  border-radius: 20px;
  margin-bottom: 32px;
  border: 1px solid $border;
  box-sizing: border-box;

  @include pmx-pre-lg {
    margin-bottom: 16px;
  }

  h4 {
    font-weight: 700;
    font-size: 20px;
    margin-bottom: 32px;
  }

  .ant-spin-nested-loading {
    width: 100%;
  }

  .content {
    display: flex;
    width: 100%;
    box-sizing: border-box;
  }
}

@include pmx-pre-lg {
  .pmx-card {
    padding: 16px;

    h4 {
      margin-bottom: 16px;
    }
  }
}
