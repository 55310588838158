@import "../../core/styles/refrences/index",
"../../assets/fonts/YekanBakh/css/font.css",
"../../assets/fonts/roboto/css/font.css";

html,
body {
  overflow-x: hidden;
}

body {
  width: 100%;
  min-height: 100vh;
  padding: 0;
  margin: 0;
  direction: rtl;
  position: relative; //for mobile header handling
}

p {
  margin-bottom: 0px !important;
}

.rtl {
  direction: rtl !important;
}

.ltr {
  direction: ltr !important;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

.d-flex {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

h1 {
  font-weight: bold !important;
}

body,
a,
button,
li,
ul,
div,
p,
table,
tbody,
thead,
tr,
td,
th,
i,
b,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: YekanBakh !important;
}

span {
  font-family: YekanBakh;
}

input {
  font-family: YekanBakh !important;
}

span.danger {
  color: $danger;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

.d-none {
  display: none !important;
}

.v-none {
  visibility: hidden !important;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.text-left {
  text-align: left !important;
}

.en {
  font-family: YekanBakh-En !important;
}

.mobile-show {
  display: none !important;
  @include pmx-pre-lg {
    display: unset !important;
  }
}

.desktop-show {
  @include pmx-pre-lg {
    display: none !important;
  }
}

.modal-card {
  max-height: 73%;
  overflow: scroll !important;
}

.ReactModal__Overlay {
  opacity: 0;
  z-index: 9998 !important;
  transform: translate3d(-100px);
  transition: all 300ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
  transform: translate3d(-100px);
}

.ant-message {
  z-index: 10000;
}

.ant-spin,
.ant-spin-nested-loading {
  max-width: 100% !important;
  width: 100% !important;
}
