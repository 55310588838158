@import "../../../../core/styles/refrences/index.scss";

.pmx-track-voucher {
  .content {
    .voucher-tracking {
      h4 {
        text-align: center;
        display: none;
      }

      .content {
        display: flex;
        gap: 16px;
        justify-content: space-between;

        @include pmx-pre-lg {
          flex-direction: column;
        }

        .pmx-button {
          max-width: 150px;
          margin: unset;

          @include pmx-pre-lg {
            width: 100%;
            max-width: unset;
          }
        }

        .pmx-input {
          margin: unset;
          height: fit-content;
          input {
            font-family: YekanBakh-En !important;
          }

          @include pmx-pre-lg {
            width: 100%;
          }
        }
      }
    }

    .voucher-info {
      h4 {
        text-align: center;
      }

      .content {
        section {
          margin: auto;
          display: flex;
          flex-direction: column;
          gap: 8px;
          width: 480px;

          .row {
            direction: rtl;
            display: flex;
            justify-content: space-between;
            gap: 32px;

            p {
              //titles
              font-weight: 700;
            }
          }
        }
      }
    }
  }
}

@include pmx-pre-lg {
  .pmx-track-voucher {
    .content {
      .voucher-tracking {
        h4 {
          display: block;
        }
        .content {
          .pmx-input {
            height: 50px;
            width: 100%;
          }

          .pmx-button {
            button {
              height: 50px;
            }
          }
        }
      }
    }
  }
}
