.pmx-edit-topic {
  .content {
    .pmx-card {
      .content {
        display: flex;
        flex-direction: column;

        .pmx-input {
          margin-top: 32px;
        }

        .pmx-button {
          margin-top: 32px;
          max-width: 500px;

          &:last-child {
            margin-top: 16px;
          }
        }
      }
    }
  }
}