.pmx-edit-wallet-modal {
  .modal-card {
    min-height: unset;

    .pmx-input {
      margin-top: 32px;
    }

    p {
      margin-bottom: 16px !important;
    }

    div {
      display: flex;
      margin-top: 32px;
      height: 48px;
      gap: 16px;

      .pmx-button {
        margin: unset;
        height: 100%;
        button {
          height: 100%;
        }
      }
    }
  }
}