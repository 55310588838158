@import "../../../core/styles/refrences/index";

.pmx-signin {
  max-width: 1920px;
  height: 100vh;
  margin: auto;
  display: flex;
  width: 100%;
  overflow: hidden;

  @include pmx-pre-lg {
    flex-direction: column;
    overflow: auto;
  }

  .right {
    width: 50%;
    padding-top: 2.5;
    padding-bottom: 2.5;
    display: flex;

    @include pmx-pre-lg {
      width: 100%;
    }

    .banner {
      position: relative;
      border-radius: 20px 80px 80px 20px;
      background: linear-gradient(67.65deg, #0066e2 32.63%, #1d82ff 70.87%);
      background-repeat: no-repeat;
      background-size: cover;
      margin: auto;
      width: 80%;
      max-width: 656px;
      height: 80%;
      max-height: 864px;
      padding: 10%;

      .box {
        background: linear-gradient(
          150deg,
          rgba(255, 255, 255, 0.4) 1.69%,
          rgba(255, 255, 255, 0.1) 98.85%
        );
        backdrop-filter: blur(30px);
        border: 1px solid $white;
        border-radius: 10px 45px 45px 10px;
        display: flex;
        height: 100%;
        padding: 10% 10% 0;

        .items {
          display: flex;
          flex-direction: column;
          width: 100%;

          .title {
            h2 {
              font-weight: 1000;
              font-size: 3rem;
              color: $white;
              text-align: right;

              @include pmx-pre-xs {
                font-size: 28px;
              }
            }
          }

          img {
            margin: auto auto -20%;
            width: 70%;
            height: 70%;
          }
        }
      }
    }
  }

  .left {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 60px;
    padding-bottom: 60px;

    @include pmx-pre-lg {
      width: 100%;
      padding: 0 24px 42px;
      margin: 32px 0 0;
    }

    .content {
      width: 100%;
      display: flex;
      flex-direction: column;
      padding-left: 60px;
      margin: auto 0;
      height: 100%;
      max-width: 656px;
      max-height: 864px;

      @include pmx-pre-lg {
        padding-left: 0;
        margin: auto;
      }

      > div:first-child > svg {
        margin-right: auto;
        cursor: pointer;

        @include pmx-pre-lg {
          display: none;
        }

        &.disabled {
          opacity: 0.5;
        }
      }

      > div:first-child > .title {
        display: flex;
        margin-top: auto;
        flex-wrap: nowrap;

        h3 {
          display: inline;
          font-weight: 900;
          font-size: 48px;

          @include pmx-size(font-size, 48px, 32px);
        }

        p {
          margin-top: 16px;
          font-size: 24px;

          @include pmx-pre-lg {
            margin-top: 10px;
          }
        }
      }

      .pmx-button {
        button {
          font-size: 20px;
        }
      }

      > .stages {
        display: flex;
        flex-direction: row-reverse;
        margin-top: auto;
        padding-top: 24px;

        @include pmx-pre-lg {
          display: none;
          padding-top: 16px;
        }

        .pmx-button {
          width: max-content;
          margin-left: 12px;
          margin-right: 0px;

          button {
            width: 32px;
            height: 32px;
          }

          &:first-child {
            margin-left: auto;
          }

          &:last-child {
            margin-right: auto;
          }
        }
      }
    }
  }
}
