@import "../../../../core/styles/refrences/index.scss";

.pmx-users-saved-wallets {
  .content {
  display: flex;
  flex-direction: column;
  }
  width: 100%;
  .filter-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;

    @include pmx-pre-lg {
      flex-direction: column;
      background: $white;
      border-top: 1px solid $table-border;
    }

    > .ant-divider {
      display: none;
    }

    .buttons,
    .fields {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      box-sizing: border-box;
    }

    .buttons {
      width: 100%;
      justify-content: flex-end;

      @include pmx-pre-lg {
        justify-content: space-between;
        margin: 16px auto;
        flex-direction: column;
        gap: 16px;
      }

      > span {
        cursor: pointer;
        width: max-content;
        color: $danger;
        min-width: 60px;
      }

      > .pmx-button, a {
        min-width: unset;
        max-width: 150px;
        width: 100%;
        margin: 0;
        margin-right: 16px;

        @include pmx-pre-lg {
          margin: 0 auto;
          width: 100%;
          max-width: unset;
        }

        &:first-child {
          max-width: 150px;
        }

        &:first-child {
          margin-right: 0;
        }

        button {
          font-weight: 500;
        }
      }
    }

    .fields {
      width: 100%;
      @include pmx-pre-lg {
        flex-direction: column;
      }

      > div {
        min-width: 130px;
        width: 100%;
        color: $blue;
        border-radius: 10px;
        margin-left: 16px;
        margin-right: 0;

        @include pmx-pre-lg {
          margin-left: 0;
        }
      }

      &:first-child {
        @include pmx-pre-lg {
          margin-top: 16px;
        }
      }
    }
  }
}