.pmx-add-wallet {
  .content {
    .pmx-card {
      .content {
        display: flex;
        flex-direction: column;

        .pmx-input {
          margin-top: 32px;
        }

        .select {
          margin: 0 auto;
          max-width: 500px;
          width: 100%;

          .ant-select {
            width: 100%;
            height: 54px;
          }
        }

        .buttons {
          margin: 0 auto;
          display: flex;
          gap: 16px;
          max-width: 500px;
          width: 100%;
          margin-top: 32px;

          .pmx-button {
            button {
              height: 64px;
            }
          }

          a {
            width: 100%;
          }
        }
      }
    }
  }
}