@import "../../core/styles/refrences/index.scss";

.ant-drawer-mask {
  background: rgba(0, 0, 0, 0.05) !important;
}

.ant-drawer-content-wrapper {
  border-radius: 20px 20px 0px 0px;
  bottom: 80px !important;
  height: 70% !important;
  position: absolute !important;
  overflow: auto;

  .navigation-drawer {
    border: 1px solid $menuBorder;
    border-left-width: 0;
    border-right-width: 0;
    border-bottom-width: 0;
    border-radius: 20px 20px 0px 0px;
    z-index: 9;
    overflow-y: auto !important;
    .ant-drawer-body {
      padding: 16px 34px;
      display: flex;
      flex-direction: column;

    }

    span.holder {
      display: inline-block;
      width: 134px;
      height: 5px;
      background-color: $skyBlue;
      opacity: 0.4;
      margin: 0 auto;
    }
    .mobile-navigationbar-item {
      &:nth-child(2) {
        margin-top: 32px;
      }
    }
  }
}
