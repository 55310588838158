@import "../../../../../core/styles/refrences/index.scss";

.pmx-order-track-detail {
  .modal-card {
    border-radius: 20px;
    width: 283px;
    height: max-content;
    top: calc(30% - 165px);
    left: calc(50% - 142px);
    padding: 24px;

    h3 {
      font-size: 16px;
      font-weight: 700;
      text-align: center;
    }

    .row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-top: 24px;

      &:last-child {
        margin-top: 16px;
      }

      .ant-statistic .ant-statistic-content {
        font-size: 14px !important;
      }

      span {
        font-size: 14px;
        color: $lightPurple;
      }

      p {
        font-size: 14px;
        display: flex;

        gap: 8px;
        align-items: center;
      }

      .long-text {
        text-wrap: nowrap;
        text-overflow: ellipsis;
        max-width: 150px;
        direction: ltr;
        overflow: hidden;
        display: block;
      }

      &.text-overflow {
        p {
          direction: ltr;
          width: 200px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      &.copy {
        p {
          margin-right: auto;
        }

        .anticon {
          margin-right: 8px;
          cursor: pointer;
          width: 16px;
        }
      }
    }
  }
}
