@import "../../../core/styles/refrences/index.scss";

.mobile-navigationbar-item {
  display: flex;
  width: 164px;
  height: 40px;
  background: $white;
  color: $blue;
  margin-top: 16px;
  @include pmx-pre-lg {
    white-space: nowrap;
  }

  &.red {
    color: $red;

    svg {
      rect {
        fill: $red;
      }

      path {
        fill: $red;
      }
    }
  }

  border-radius: 10px;

  svg {
    rect {
      fill: $blue;
    }

    path {
      fill: $blue;
    }
  }

  &.filled {
    background: $primary;
    color: $white;

    svg {
      rect {
        fill: $white;
      }

      path {
        fill: $white;
      }
    }
  }

  &.active {
    background: $primary;
    color: $white;

    svg {
      rect {
        fill: $white;
      }

      path {
        fill: $white;
      }
    }
  }

  .content {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 0 10px;

    p {
      font-size: 16px;
      font-weight: 700;
      padding: 0 9px 0 0;
      display: flex;
      gap: 16px;
      align-items: center;

      .pmx-badge {
        transform: translateY(-2px)
      }
    }
  }
}

.mobile-navigationbar-item-children {
  display: flex;
  flex-direction: column;
  width: fit-content;
  padding-right: 10px;

  a {
    margin-top: 16px;
    color: $blue;
    display: flex;
    gap: 16px;
    align-items: center;

    .pmx-badge {
      transform: translateY(-2px)
    }
  }
}
