@import "../../../../../core/styles/refrences/index.scss";

.pmx-audit-log-get-by-id {
  .pmx-card {
    .content {
      width: 100%;
      flex-direction: column;
      .log-info {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        row-gap: 16px;
        justify-content: space-between;
        align-items: center;

        .ant-row {
          width: 100%;
          display: flex;
          justify-content: start;
          align-items: center;
          column-gap: 8px;
          flex-wrap: nowrap;
        }
      }
    }
  }
}
