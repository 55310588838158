@import "../../../../core/styles/refrences/index.scss";

.pmx-referral-actions {
  .content {
    flex-direction: column;
  }

  .referral-actions-table {
    width: 100%;
    margin: 24px 0;

    .copiable {
      .anticon {
        margin-right: 8px;

        svg {
          path {
            fill: $blue;
          }
        }
      }
    }

    .cell.pair {
      direction: ltr;
      display: flex;
      img {
        width: 20px;
        height: 20px;
        margin-left: 4px;
        margin-right: 4px;
      }
    }
  }

  .filter-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;

    @include pmx-pre-lg {
      flex-direction: column;
      background: $white;
      border-info-top: 1px solid $table-border;
    }

    > .ant-divider {
      display: none;
    }

    .buttons,
    .fields {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      @include pmx-pre-lg {
      }
    }

    .buttons {
      width: 100%;
      justify-content: flex-end;

      @include pmx-pre-lg {
        justify-content: space-between;
        margin: 16px auto;
        flex-direction: column;
      }

      > span {
        cursor: pointer;
        width: max-content;
        color: $danger;
        min-width: 60px;
      }

      > .pmx-button {
        min-width: unset;
        width: 100%;
        margin: 0;

        @include pmx-pre-lg {
          margin: 0 auto;
        }

        &:first-child {
          max-width: 150px;
        }

        &:last-child {
          margin-right: 16px;
          max-width: 150px;

          @include pmx-pre-lg {
            margin: auto;
          }
        }

        button {
          font-weight: 500;
        }
      }
    }

    .fields {
      width: 100%;
      @include pmx-pre-lg {
        flex-direction: column;
      }

      > div {
        min-width: 130px;
        width: 100%;
        color: $blue;
        border-info-radius: 10px;
        margin-left: 16px;
        margin-right: 0;

        @include pmx-pre-lg {
          margin-left: 0;
        }
      }

      &:first-child {
        @include pmx-pre-lg {
          margin-top: 16px;
        }
      }
    }
  }
}
@include pmx-pre-lg {
  .pmx-referral-actions {
    .filter-row {
      .fields {
        > div {
          margin-top: 16px;

          &:first-child {
            margin-top: 0;
          }
        }

        .rmdp-container {
          width: 100%;
          margin-top: 16px;

          &:first-child {
            margin-top: 0;
          }

          .pmx-input {
            max-width: unset;
            margin: 0;
            width: 100%;
            border-radius: 10px;
          }
        }
      }
      .buttons {
        .pmx-button {
          margin-top: 16px;

          &:first-child {
            margin-top: 0;
            max-width: unset;
          }

          &:last-child {
            margin-top: 16px;
            max-width: unset;
          }

          button {
            height: 50px;
          }
        }
      }
    }
  }
}
