@import "../../../../core/styles/refrences/index.scss";

.pmx-bank-accounts {
  .content {
    flex-direction: column;

    > .pmx-button {
      min-width: 150px;
      width: max-content;
      margin-right: auto;
      margin-left: 0;
      margin-bottom: 24px;

      @include pmx-pre-lg {
        width: 100%;
      }
    }
  }

  .bank-accounts-table {
    width: 100%;
    margin: 24px 0;

    .copiable {
      .anticon {
        margin-right: 8px;

        svg {
          path {
            fill: $blue;
          }
        }
      }
    }

    .cell.pair {
      direction: ltr;
      display: flex;
      img {
        width: 20px;
        height: 20px;
        margin-left: 4px;
        margin-right: 4px;
      }
    }
  }

  .filter-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;

    @include pmx-pre-lg {
      flex-direction: column;
      background: $white;
      border-info-top: 1px solid $table-border;
    }

    > .ant-divider {
      display: none;
    }

    .buttons,
    .fields {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    .buttons {
      width: 100%;
      justify-content: flex-end;

      @include pmx-pre-lg {
        margin-top: 16px;
        flex-direction: column;
        justify-content: space-between;
        margin-bottom: 16px;
      }

      > span {
        cursor: pointer;
        width: max-content;
        color: $danger;
        min-width: 60px;
      }

      > .pmx-button {
        min-width: unset;
        width: 100%;
        margin: 0;

        @include pmx-pre-lg {
          margin: 0 auto;
        }

        &:first-child {
          max-width: 150px;

          @include pmx-pre-lg {
            margin-right: unset;
            max-width: unset;
          }
        }

        &:last-child {
          margin-right: 16px;
          max-width: 150px;

          @include pmx-pre-lg {
            margin-right: unset;
            max-width: unset;
          }
        }

        button {
          font-weight: 500;
        }
      }
    }

    .fields {
      width: 100%;
      @include pmx-pre-lg {
        flex-direction: column;
      }

      > div {
        min-width: 130px;
        width: 100%;
        color: $blue;
        border-info-radius: 10px;
        margin-left: 16px;
        margin-right: 0;

        @include pmx-pre-lg {
          margin-left: 0;
        }
      }

      &:first-child {
        @include pmx-pre-lg {
          margin-top: 16px;
        }
      }
    }
  }
}
.confirm-delete-card-modal {
  .modal-card {
    width: 500px;
    @include pmx-pre-lg {
      width: 355px;
    }
    justify-content: space-between;
    p {
      font-weight: 500;
      font-size: 18px;
      text-align: center;
    }

    .buttons {
      margin-top: 24px;
      .pmx-button {
        display: flex;
        align-items: center;
        justify-content: center;
        button {
          width: 180px;
          height: 60px;
          @include pmx-pre-lg {
            height: 50px;
          }
        }
      }
    }
  }
}
@include pmx-pre-lg {
  .pmx-bank-accounts {
    .content {
      > .pmx-button {
        margin: 0;

        button {
          height: 50px;
        }
      }
    }
    .filter-row {
      .fields {
        > div {
          margin-top: 16px;

          &:first-child {
            margin-top: 0;
          }
        }

        .rmdp-container {
          width: 100%;
          margin-top: 16px;

          &:first-child {
            margin-top: 0;
          }

          .pmx-input {
            max-width: unset;
            margin: 0;
            width: 100%;
            border-radius: 10px;
          }
        }
      }
      .buttons {
        .pmx-button {
          margin-top: 16px;

          &:first-child {
            margin-top: 0;
          }

          &:last-child {
            margin-top: 16px;
          }

          button {
            height: 50px;
          }
        }
      }
    }
  }
}
