@import "../../core/styles/refrences/index";

.pmx-input {
  max-width: 500px;
  margin: 0 auto;
  position: relative;
  padding: 13px 12px 13px;
  border: 1px solid $border;
  border-radius: 20px;
  width: calc(100% - 48px);
  background: $secondary;
  box-sizing: border-box;
  display: flex;
  align-items: center;

  * {
    box-sizing: border-box;
  }

  label {
    position: absolute;
    top: -24px;
    right: 0;
  }

  .prefix {
    display: flex;
    align-items: center;
    height: 100%;
    margin-left: 8px;
    transform: translateY(1px);
  }

  .suffix {
    display: flex;
    align-items: center;
    height: 100%;
    margin-right: 8px;
  }

  ::placeholder {
    color: $lightPurple;
    opacity: 1; /* Firefox */
  }

  input {
    background-color: $secondary;
  }

  input {
    width: 100%;
    font-size: 16px;
    padding: 0;
    outline: none;
    border: 0;
    background: none;
  }

  .adornments {
    position: absolute;
    top: calc(50% - 15px);
    left: 15px;
    display: flex;
    align-items: center;
    height: 30px;

    img,
    svg,
    .anticon {
      max-width: 30px;
      max-height: 30px;
    }

    svg {
      path {
        fill: $lightPurple;
      }
    }
  }
}
