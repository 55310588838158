.pmx-edit-content {
  .content {
    .pmx-card {
      .content {
        display: flex;
        flex-direction: column;

        label {
          &:first-child {
            width: 500px;
            margin: 0 auto;
          }

          &:last-child {
            margin-top: 32px;
          }
        }

        .pmx-dropdown {
          border-radius: 20px;

          .dropdown-items {
            width: 100%;
          }
        }

        .pmx-input {
          margin-top: 32px;
        }

        .pmx-button {
          margin-top: 32px;
          max-width: 500px;

          &:last-child {
            margin-top: 16px;
          }
        }

        .tox {
          margin-top: 16px;
        }
      }
    }
  }
}