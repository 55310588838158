@import "src/core/styles/refrences/index.scss";

.pmx-edit-market-content .content-market-stage {

  label {
    font-size: 18px;
    margin-bottom: 20px;
  }

  textarea {
    height: 200px;
  }

  .content {

    .uploader-row {
      flex-direction: row;
      justify-content: space-evenly;
      align-items: center;

      .file-uploader {
        width: 49%;
      }

    }
  }

  .pmx-input {
    margin-right: 0;
  }

  .color {
    margin-top: 32px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;

    label {
      margin-right: 0 !important;
      margin-bottom: 0 !important;
    }

    .react-colorful {
      margin-top: 16px;
    }
  }

  .row {
    margin-top: 32px;
    width: 100%;
    display: flex;
    align-items: start;
    justify-content: center;
    flex-direction: column;


    .ant-image {
      margin-top: 16px;
    }

    button.upload-button {
      background: transparent;
      border: none;
      display: flex;
      width: 100%;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0;
      cursor: pointer;

      > p {
        margin-top: 8px;
      }

      svg {
        width: 32px;
        height: 32px;

        @include pmx-pre-lg {
          width: 20.36px;
          height: 20.36px;
        }

        path {
          fill: $lightBlue;
        }
      }
    }
  }
}
