@import "../../core/styles/refrences/index.scss";

.pmx-button {
  width: 100%;
  margin: 0 auto;
  cursor: pointer;

  button {
    border: 0;
    outline: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 64px;
    border-radius: 20px;
    font-weight: 700;
    transition: 0.2s;

    > .anticon,
    > svg,
    > img {
      &:last-child {
        margin-right: 8px;
      }
      &:first-child {
        margin-left: 8px;
      }
    }

    &:hover {
      cursor: pointer;
      opacity: 0.94;
      transition: 0.2s;
    }

    &.disable {
      background: $disable-primary !important;
      cursor: revert !important;
    }

    &.info {
      background: $primary;
      color: $white;
    }

    &.error {
      background: $danger;
      color: $white;
    }

    &.cancel {
      background: $cancel;
      color: $red;
    }

    &.white {
      background-color: $white;
      color: $blue;
    }

    &.outlined {
      background: $white;
      border: 1px solid $lightPurple !important;
      color: $lightPurple;
      height: 56px;
      transition: 0.4s;
      font-weight: 400 !important;

      &:hover {
        color: $black;
        transition: 0.4s;
        border: 1px solid $black !important;
      }
    }

    &.success {
      background: $success;
      color: $white;
    }

    &.secondary {
      background: $skyBlue;
      color: $lightBlue;

      &:hover {
        color: $white;
        background: $primary;
        transition: 0.4s;
      }
    }
  }
}
