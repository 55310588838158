@import "../../core/styles/refrences/index";

body {
}

.pmx-sidebar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  width: 13%;
  align-items: center;
  background: $white;

  @include pmx-pre-lg {
    display: none;
  }

  .sidebar-head {
    margin-top: 14%;

    img {
      width: 139px;
    }
  }

  .sidebar-main {
    padding: 20px 0;
    height: 70%;
    width: calc(100% - 20px);
    align-self: start;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 0px;
    }

    .main-head {
      flex-direction: column;
      display: flex;
      margin-bottom: 48px;
      padding-right: 7%;

      p {
        font-size: 24px;
        font-weight: 900;
      }

      span {
        font-size: 13px;
      }

      .avatar {
        width: 108px;
        height: 108px;
        background: $skyBlue;
        align-items: center;
        justify-content: center;
        display: flex;
        border-radius: 18px;

        svg {
          rect {
            fill: $blue;
          }

          path {
            fill: $blue;
          }

          width: 62px;
          height: 62px;
        }
      }
    }

    .menu {
      width: 100%;
      justify-content: space-evenly;
      display: flex;
      flex-direction: column;
    }
  }

  .sidebar-footer {
    p {
      text-align: center;
    }

    margin-bottom: 8%;
  }
}

.logout-modal {
  .modal-card {
    justify-content: space-between;
    
    @include pmx-pre-lg {
      width: 70% !important;
      left: 7% !important;
    }

    p {
      font-weight: 500;
      font-size: 18px;
      text-align: center;
    }

    .buttons {
      margin-top: 24px;

      .pmx-button {
        button {
          width: 180px;
          height: 60px;

          @include pmx-pre-lg {
            width: 100%;
            height: 50px;
          }
        }

        &:last-child {
          margin-right: 16px;
        }
      }
    }
  }
}
