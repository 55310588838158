@import "core/styles/refrences/index.scss";
.pmx-pagination {
  width: 100%;
  margin-top: 32px;
  text-align: center;

  .pagination-row {
    display: flex;
    justify-content: center;
    align-items: center;

    > .anticon {
      cursor: pointer;
      margin-right: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 15px;
      border: 1px solid $table-border;
      height: 40px;
      width: 40px;
      color: $blue;
      transition: 0.4s;

      @include pmx-pre-lg {
        margin-right: 3px;
      }

      &.anticon-double-right,
      &.anticon-double-left {
        @include pmx-pre-lg {
          display: none;
        }
      }

      &.disabled {
        opacity: 0.5;
      }

      &:hover {
        background: $primary;
        color: $white;
        transition: 0.4s;
      }
    }

    .pages {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .pmx-button {
      margin-right: 10px;
      margin-left: 0;
      width: 56px;
      @include pmx-pre-lg {
        margin-right: 3px;
      }
      button {
        height: 56px;
        padding: 10px;
        border-radius: 15px;
        border: 1px solid $table-border !important;

        &.info {
          background: $primary;
        }
      }
    }
  }

  .foot {
    margin: 16px auto;
    font-size: 16px;
    b {
      font-weight: 500;

      margin: 0px 4px;
    }
  }
}

@include pmx-pre-lg {
  .pmx-pagination {
    .pagination-row {
      width: 100%;
      justify-content: space-between;

      .pmx-button {
        width: 40px;

        button {
          height: 40px;
        }
      }
    }
  }
}
