@import "../../core/styles/refrences/index";

.pmx-dropdown {
  position: relative;
  width: 100%;
  max-width: 500px;
  min-width: 375px;
  margin: 0 auto;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: 1px solid $border;
  background: $secondary;
  padding: 13px 12px 13px;
  box-sizing: border-box;

  * {
    box-sizing: border-box;
  }

  .dropdown-selector {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    cursor: pointer;

    img {
      width: 13px;
    }

    a {
      color: $lightPurple !important;
      display: flex;
      align-items: center;
      font-size: 14px;
      font-size: 16px;
    }

    .anticon {
      width: 13px;
      cursor: pointer;

      svg {
        fill: $primary;
      }
    }
  }

  .dropdown-items {
    position: absolute;
    width: max-content;
    right: 0;
    height: max-content;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    background: $white;
    top: 102%;
    z-index: 999;
    margin-top: 8px;

    display: flex;
    align-items: center;
    justify-content: right;

    div {
      width: 100%;
      height: 100%;
      align-items: center;
      margin: 12px 4px;
      border-radius: 8px;
      display: flex;

      span {
        margin: 7px 12px;
        white-space: nowrap;
      }
    }

    ul {
      width: 100%;
      height: max-content;
      list-style: none;
      padding-right: 0px !important;
      margin: 12px 0;

      li {
        padding: 0 8px;
        width: 100%;
        height: 38px;
        color: $black;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        cursor: pointer;

        svg {
          margin-left: 6px;
        }

        > span:last-child {
          display: flex;
          align-items: center;
        }
      }

      .ant-divider {
        margin-bottom: 0;
        margin-top: 0;
      }

      div:hover {
        background: $secondary;
      }
    }
  }
}
