@import "src/core/styles/refrences/index";

.pmx-edit-market-content .pmx-card.comments-stage {
  .comments {
    display: flex;
    flex-direction: column;
    border: 1px solid $lightGray;
    border-radius: 10px;

    > .head {
      width: 100%;
      display: flex;
      justify-content: end;
      padding: 16px;
      box-sizing: border-box;
    }

    .add-head {
      width: 100%;
      display: flex;
      justify-content: end;
      align-items: center;
      gap: 16px;
      padding: 16px 0;

      box-sizing: border-box;
    }

    .anticon {
      svg {
        width: 32px;
        height: 32px;
      }
    }

    .remove-comment {
      margin-right: auto;
      z-index: 10;

      .anticon {
        color: $red;
      }
    }

    .add-comment-icon {
      margin-right: auto;
      z-index: 10;

      .anticon {
        color: $green;
      }
    }

    .edit-comment {
      margin-right: 16px;
      z-index: 10;

      .anticon {
        color: $blue;
      }

      .check-icon {
        color: $success;
      }
    }

    .toggle-comment {
      margin-right: 16px;
      z-index: 10;

      &.active {
        .anticon {
          transform: rotate(180deg);
        }
      }
    }

    .comment-item {
      display: flex;
      flex-direction: column;
      border-bottom: 1px solid $lightGray;
      box-sizing: border-box;
      padding: 16px 0;

      .comment-text {
        padding: 16px 16px 16px;
        border-bottom: 1px solid $gray;
      }

      .edit-comment-input {
        padding: 0 16px;
        margin-top: 32px;
      }

      .head {
        width: 100%;
        display: flex;
        padding: 16px;
        cursor: pointer;
        box-sizing: border-box;
      }
    }
  }
}
