@import "../../../../core/styles/refrences/index.scss";

.pmx-overview {
  background: $secondary;
  border-radius: 16px;

  h1 {
    padding: 16px;
  }

  .row {
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: 32px;

    .filter-row {
      width: calc(100% - 96px);
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 24px;
      border-radius: 20px;
      background: #E9EEFF;
      height: 80px;

      @include pmx-pre-lg {
        flex-direction: column;
        background: $white;
        border-top: 1px solid $table-border;
      }

      > .ant-divider {
        display: none;
      }

      .buttons,
      .fields {
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }

      .buttons {
        justify-content: flex-end;
        margin-left: unset !important;

        @include pmx-pre-lg {
          margin-top: 16px;
          flex-direction: column;
          justify-content: space-between;
          margin-bottom: 16px;
        }

        > span {
          cursor: pointer;
          width: max-content;
          color: $danger;
          min-width: 60px;
        }

        > .pmx-button {
          min-width: unset;
          width: 100%;
          margin: 0;
          margin-right: 16px;

          &:first-child {
            margin-right: 0;

            button {
              background: linear-gradient(53.23deg, #3364E4 27.34%, #333DC2 78.62%);

              &.disable {
                background: linear-gradient(53.23deg, #3364E4 27.34%, #333DC2 78.62%) !important;
              }
            }
          }

          &:last-child {
            button {
              &.disable {
                background: linear-gradient(91.95deg, #fc2a2a -14.4%, #ff4646 91.65%) !important;
              }
            }
          }

          @include pmx-pre-lg {
            margin: 0 auto;
            font-size: 12px;
            width: 100%;
            max-width: unset;
          }
          max-width: 150px;

          button {
            font-weight: 500;
            font-size: 12px;
          }
        }
      }

      .fields {
        width: 100%;
        @include pmx-pre-lg {
          flex-direction: column;
        }

        .rmdp-container {
          .pmx-input {
            width: unset;
          }
        }

        > div {
          min-width: 135px;
          width: 100%;
          color: $blue;
          border-radius: 10px;
          margin-left: 16px;
          margin-right: 0;

          @include pmx-pre-lg {
            margin-left: 0;
          }
        }

        &:first-child {
          @include pmx-pre-lg {
            margin-top: 16px;
          }
        }
      }
    }

  }

  .pmx-charts {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 32px;
    margin-top: 32px;

    .pmx-card {
      margin-bottom: unset
    }
  }
}