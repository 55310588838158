@import "../../../../../../core/styles/refrences/index.scss";

.pmx-usdt-withdrawal-count {
  .content {
    display: flex;
    flex-direction: column;


    .pmx-chart {
      margin-inline: auto;

      @include pmx-pre-lg {
        width: 320px !important;
        height: 240px !important;
      }

      .recharts-surface {
        width: 100%;
      }
    }
  }
}