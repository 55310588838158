@import "../../core/styles/refrences/index";

.pmx-data-table {
  margin: 24px;
  width: 100%;

  ::-webkit-scrollbar {
    width: 4px;
  }

  ::-webkit-scrollbar-thumb {
    background: #c9cfd8;
  }

  ::-webkit-scrollbar-track {
    background: $white;
  }

  div[role="table"] div[role="rowgroup"] div[role="row"] {
    height: 62px !important;
  }

  .actions {
    .anticon,
    > svg {
      margin-right: 8px;

      &:first-child {
        margin-right: 0;
      }
    }
  }

  div[role="cell"] {
    @include pmx-pre-lg {
    max-width: unset;
    min-width: unset;
    white-space: nowrap;
    text-overflow: unset;
    }
  }

  .pagination-footer {
    width: 100%;
    min-height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;

    > .anticon {
      cursor: pointer;
      margin-right: 10px;
      padding: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 6px;
      border: 1px solid $table-border;
      height: 40px;
      width: 40px;
      color: $blue;
      transition: 0.4s;

      &:hover {
        background: $primary;
        color: $white;
        transition: 0.4s;
      }
    }

    .pages {
      display: flex;
      justify-content: center;
      align-items: center;

      .pmx-button {
        margin-right: 10px;
        button {
          height: 40px;
          width: 40px;
          padding: 10px;
          border-radius: 6px;
          border: 1px solid $table-border !important;

          &.info {
            background: $primary;
          }
        }
      }
    }
  }

  header {
    display: flex;
    flex-direction: row-reverse;
    padding: 16px;
    align-items: center;
    justify-content: space-between;
    .pmx-input {
      @include pmx-size(width, 95%, 80%);
      max-width: 344px;

      .input-icon {
        left: 4% !important;
        right: unset !important;
      }
      input {
        width: 70%;
      }
    }
    .pmx-dropdown {
      border-radius: 10px;
      @include pmx-pre-md {
        width: 17%;
        min-width: unset;
        max-width: unset;
      }
      .dropdown-selector {
        a {
          svg {
            @include pmx-pre-md {
              margin-left: unset;
            }
          }
          span {
            @include pmx-pre-md {
              display: none;
            }
          }
        }
        .anticon {
          @include pmx-pre-md {
            display: none;
          }
        }
      }
    }
  }
  &.non-header {
    header {
      display: none !important;
    }
  }
}
