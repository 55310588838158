@mixin rtl {
  @at-root body[dir="rtl"] & {
    @content;
  }
}

@mixin pmx-xs {
  @media (max-width: 575.98px) {
    @content;
  }
}

@mixin pmx-sm {
  @media (min-width: 575.98px) {
    @content;
  }
}

@mixin pmx-md {
  @media (min-width: 767.98px) {
    @content;
  }
}

@mixin pmx-lg {
  @media (min-width: 991.98px) {
    @content;
  }
}

@mixin pmx-xl {
  @media (min-width: 1199.98px) {
    @content;
  }
}

@mixin pmx-pre-xs {
  @media (max-width: 575.98px) {
    @content;
  }
}

@mixin pmx-pre-sm {
  @media (max-width: 575.98px) {
    @content;
  }
}

@mixin pmx-pre-md {
  @media (max-width: 767.98px) {
    @content;
  }
}

@mixin pmx-pre-lg {
  @media (max-width: 991.98px) {
    @content;
  }
}

@mixin pmx-pre-xl {
  @media (max-width: 1199.98px) {
    @content;
  }
}

@mixin pmx-size($property, $web-value, $mobile-value, $important: null) {
  @include pmx-xs {
    #{$property}: $mobile-value $important;
  }
  @include pmx-sm {
    #{$property}: (2 * $mobile-value + $web-value)/3 $important;
  }
  @include pmx-md {
    #{$property}: ($mobile-value + $web-value)/2 $important;
  }
  @include pmx-lg {
    #{$property}: ($mobile-value + 2 * $web-value)/3 $important;
  }
  @include pmx-xl {
    #{$property}: $web-value $important;
  }
}

@mixin rtl {
  @at-root body[dir="rtl"] & {
    @content;
  }
}

@mixin ltr {
  @at-root body[dir="ltr"] & {
    @content;
  }
}
