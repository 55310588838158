@import "../../../core/styles/refrences/index";

.sidebar-item {
  width: calc(100% - 28px);
  margin-top: 8px;

  &.active {
    a {
      background: $primary;
      color: $white;
    }

    svg {
      rect {
        fill: $white;
      }

      path {
        fill: $white;
      }
    }
  }

  &.red {
    a {
      color: $red;
    }

    svg {
      rect {
        fill: $red;
      }

      path {
        fill: $red;
      }
    }
  }

  &.filled {
    background: $primary;
    color: $white;

    svg {
      rect {
        fill: $white;
      }

      path {
        fill: $white;
      }
    }
  }

  a {
    border-radius: 40px 0 0 40px;
    display: flex;
    padding: 8px 16px;
    color: $blue;
    font-weight: 700;
    font-size: 20px;
    width: 100%;
    position: relative;
    align-items: center;

    svg {
      &.expanded {
        transform: rotate(90deg);
      }

      rect {
        fill: $blue;
      }

      path {
        fill: $blue;
      }
    }

    > svg {
      //position: absolute;
      //left: 16px;
      //top: 50%;
    }

    .content {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      padding: 0 10px;

      p {
        padding: 0 9px 0 0;
        font-size: 13px;

        display: flex;
        align-items: center;
        gap: 8px;

        .pmx-badge {
          margin-top: unset;
        }
      }
    }
  }

  ul.item-body {
    width: 100%;
    list-style-type: none;
    padding: 0;
    margin-top: 10px;
    display: flex;
    flex-direction: column;

    a {
      margin-top: -10px;

      li {
        padding: 8px;
        width: 100%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: $blue;
        font-size: 13px;

        .pmx-badge {
          margin: unset;

          div {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 16px;
          }
        }

        &.active {
          background-color: $blue;
          color: $white;
          width: 100%;
          margin: 0;
          border-radius: 20px;
        }
      }

      &:last-child {
        margin-bottom: -20px;
      }
    }
  }
}
