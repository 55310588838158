@import "core/styles/refrences/index.scss";

.edit-role-modal {
  .google-auth {
    width: 100%;
    margin-top: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &.code {
      .input {
        direction: ltr;
        > div {
          display: flex;
          justify-content: space-evenly;
          align-items: center;
          > input {
            margin: 0 10px;
            border: 1px solid $table-border;
            border-radius: 10px;
            max-height: 48px;

            @include pmx-pre-md {
              margin: 0 5px;
            }
          }
        }
      }
      .input.has-error {
        input {
          border-color: $danger;
        }
      }

      .input.has-error {
        input {
          border-color: $danger;
        }
      }

      .stats {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        width: 100%;
        margin: 10px 0;

        span.resend {
          cursor: pointer;
          color: $blue;
        }

        .timer {
          justify-self: flex-start;
          display: flex;
          justify-content: center;
          align-items: center;

          > div {
            left: auto !important;
            top: auto !important;
            > svg {
              display: none;
            }
            > div {
              position: relative !important;
              height: 30px !important;
              width: 30px !important;
            }
          }
        }
        .errors {
          display: flex;
          justify-self: flex-start;
          justify-content: flex-start;
          align-items: center;
          column-gap: 4px;
          color: $danger;
          font-size: 12px;
          min-width: 50px;
        }
        > span {
          min-width: 50px;
        }
      }

      .timer {
        justify-self: center;
        display: flex;
        justify-content: center;
        align-items: center;

        > div {
          left: auto !important;
          top: auto !important;
          > svg {
            display: none;
          }
          > div {
            position: relative !important;
            height: 30px !important;
            width: 30px !important;
          }
        }
      }
      .change-number {
        margin-top: 10px;
        color: $blue;
        cursor: pointer;
      }
    }

    &.app-code {
      .input {
        direction: ltr;
        > div {
          display: flex;
          justify-content: space-evenly;
          align-items: center;
          > input {
            margin: 0 5px;
            border: 1px solid $table-border;
            border-radius: 10px;
            max-height: 48px;

            @include pmx-pre-md {
              margin: 0 5px;
            }
          }
        }
      }
    }
  }
}

.pmx-dashboard-roles-edit {
  padding: 24px;
  height: auto;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  column-gap: 50px;
  background: $white;

  border-radius: 20px;
  h3 {
    margin-bottom: 24px;
  }

  form {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    padding: 8px;

    .pmx-input {
      width: 100%;
    }

    textarea.ant-input {
      margin-top: 24px;
      width: 100%;
      min-height: 250px;
      max-height: 250px;
    }

    > .row {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .pmx-input {
        width: 50%;
      }

      .pmx-input:last-child {
        margin-right: 24px;
      }

      .pmx-button {
        margin-top: 24px;
        width: 50%;
      }
    }

    .buttons {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      @include pmx-pre-lg {
        justify-content: space-between;
      }

      .pmx-button {
        margin: 0;
        margin-top: 24px;

        &:last-child {
          margin-right: 24px;
        }

        button {
          height: 48px !important;
        }
      }
    }

    .roles-transfer {
      direction: ltr;
      margin-top: 24px;
      width: 100%;

      @include pmx-pre-lg {
        flex-direction: column;
      }

      .ant-transfer-list-header-selected {
        direction: rtl;
      }

      .ant-transfer-list-content {
        direction: rtl;

        .ant-transfer-list-content-item-text {
          margin-right: 4px;
        }
      }

      .ant-transfer-operation {
        @include pmx-pre-lg {
          margin: 16px 0;
        }
      }

      .ant-transfer-list {
        direction: ltr;
        width: 50%;
        border-radius: 10px;

        @include pmx-pre-lg {
          width: 100%;
        }
      }
    }

    > .pmx-input {
      width: 100%;
      margin-top: 24px;
    }

    .menu-items-transfer {
      direction: ltr;
      margin-top: 24px;
      width: 100%;

      @include pmx-pre-lg {
        flex-direction: column;
      }

      .ant-transfer-list-header-selected {
        direction: rtl;
      }

      .ant-transfer-list-content {
        direction: rtl;

        .ant-transfer-list-content-item-text {
          margin-right: 4px;
        }
      }

      .ant-transfer-operation {
        @include pmx-pre-lg {
          margin: 16px 0;
        }
      }

      .ant-transfer-list {
        direction: ltr;
        width: 50%;
        border-radius: 10px;

        @include pmx-pre-lg {
          width: 100%;
        }
      }
    }

    > .pmx-input {
      width: 100%;
      margin-top: 24px;
    }
  }
}
