@import "../../core/styles/refrences/index.scss";

.mobile-navigation {
  width: 100%;
  height: 98px;
  background: $white;
  border-radius: 20px 20px 0px 0px;
  position: fixed;
  bottom: -2px;
  left: 0;
  padding: 16px 40px;
  display: flex !important;
  border: 1px solid $menuBorder;
  border-left-width: 0;
  border-right-width: 0;
  border-bottom-width: 0;
  z-index: 9999;
  box-sizing: border-box;

  &.active {
    border-width: 0;
  }

  .item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    opacity: 0.5;

    &.active,
    &.menu-button {
      opacity: 1;
    }

    &.menu-button {
      margin-top: -32px;
      .icon {
        display: flex;
        padding: 11px;
        height: 54px;
        width: 54px;
        background-color: $blue;
        border-radius: 14px;
        align-items: center;
        justify-content: center;

        svg {
          path {
            fill: $white;
          }
        }
      }
    }

    &:first-child {
      margin-right: 0;
    }
    &:last-child {
      margin-left: 0;
    }

    span {
      color: $blue;
      margin-top: 8px;
      font-weight: 700;
      font-size: 14px;
    }

    svg {
      width: 32px;
      height: 32px;
      path {
        fill: $blue;
      }
    }
  }
}
